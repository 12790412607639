import { useState } from 'react';

interface HintMessageProps {
  message: string;
}

const HintMessage = ({ message }: HintMessageProps) => {
  const [showCoachMark, setShowCoachMark] = useState(false);

  return (
    <div
      className="text-md relative flex h-6 w-6 cursor-pointer items-center justify-center rounded-full border border-gray-500 text-sm text-[#777777]"
      onClick={() => setShowCoachMark(!showCoachMark)}
    >
      {showCoachMark && (
        <span
          className={`absolute right-7 top-full z-10 mt-4 flex w-80 translate-x-full transform rounded-sm border border-black bg-white p-2.5 text-13 text-black after:absolute after:bottom-full after:left-4 after:block after:-translate-x-1/2 after:border-8 after:border-transparent after:border-b-black`}
        >
          <p
            className="whitespace-pre-wrap text-14 font-normal leading-6"
            dangerouslySetInnerHTML={{
              __html: message.replace(/\n/g, '<br />'),
            }}
          ></p>
        </span>
      )}
      <p>?</p>
    </div>
  );
};

export default HintMessage;
