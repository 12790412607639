import _ from 'lodash';
import { useEffect, useState } from 'react';
import { BackButton, Blank, Label, Select, Textarea, TopNavbar } from 'src/components/common';
import { Button } from 'src/components/common/Button';
import { Icon } from 'src/components/common/icons';
import { TextInput } from 'src/components/common/TextInput';
import { useStudentSelfAssessmentCreate, useStudentSelfAssessmentFindMyAssessment } from 'src/generated/endpoint';
import { twMerge } from 'tailwind-merge';
import { SELF_TEST_TYPES } from './self-test.type';

export const SelfTestPage = () => {
  const { data: selfAssessment, isLoading: getLoading } = useStudentSelfAssessmentFindMyAssessment();

  const [category1, setCategory1] = useState('');
  const [category2, setCategory2] = useState('');
  const [keywords, setKeywords] = useState<Record<number, { keyword: string; reason: string }>>({});
  const [assessment, setAssessment] = useState<string>('');

  const { mutate: createSelfAssessment, isLoading: createLoading } = useStudentSelfAssessmentCreate();

  const buttonDisabled = !category1 || !category2 || !Object.keys(keywords).length;

  useEffect(() => {
    if (selfAssessment && !category1 && !category2 && !Object.values(keywords).length && !assessment) {
      setCategory1(selfAssessment?.category1);
      setCategory2(selfAssessment?.category2);
      setKeywords(selfAssessment?.keywords);
      setAssessment(selfAssessment?.assessment);
    }
  }, [selfAssessment]);

  const isLoading = getLoading || createLoading;

  return (
    <>
      {isLoading && <Blank />}
      <TopNavbar
        title="자기 평가"
        left={
          <div className="h-15">
            <BackButton className="h-15" />
          </div>
        }
      />
      <div className="flex flex-col space-y-4 p-6">
        <h1 className="text-gray-600">
          학생의 행동특성에 대한 기록를 위한 자기평가입니다. 본인과 담임선생님만 확인이 가능합니다.
        </h1>
        <Label.col>
          <Label.Text children="범주1" />
          <Select.lg
            placeholder="선택"
            value={category1}
            onChange={(e) => {
              setCategory1(e.target.value);
              setCategory2('');
            }}
          >
            <option selected hidden value="">
              범주를 선택해주세요.
            </option>
            {SELF_TEST_TYPES.map((ct1) => (
              <option key={ct1.id} value={ct1.name}>
                {ct1.name}
              </option>
            ))}
          </Select.lg>
        </Label.col>
        <Label.col>
          <Label.Text children="범주2" />
          <Select.lg
            placeholder="선택"
            disabled={!category1}
            value={category2}
            onChange={(e) => {
              setCategory2(e.target.value);
            }}
          >
            <option selected hidden value="">
              범주를 선택해주세요.
            </option>
            {category1 &&
              SELF_TEST_TYPES.filter((ct1) => ct1.name === category1)[0].values.map((ct2) => (
                <option key={ct2.id} value={ct2.name}>
                  {ct2.name}
                </option>
              ))}
          </Select.lg>
        </Label.col>
        {category2 && (
          <Label.col>
            <Label.Text children="특성단어" />
            <Label.Text className="text-16">본인을 나타내는 특성단어를 선택해주세요.</Label.Text>
            <div className="mt-2">
              {SELF_TEST_TYPES.filter((ct1) => ct1.name === category1)[0]
                .values.filter((ct2) => ct2.name === category2)[0]
                .values.map((el) => {
                  const selected = Object.values(keywords).some(({ keyword, reason }) => el === keyword);
                  return (
                    <div
                      className={twMerge(
                        'mb-2 mr-2 inline-block cursor-pointer rounded-full border border-gray-600 px-4 py-2',
                        selected && 'border-brand-1 bg-light_orange text-brand-1',
                      )}
                      onClick={() => {
                        if (selected) {
                          setKeywords(_.omitBy(keywords, ({ keyword }) => keyword === el));
                        } else {
                          const key =
                            _.chain(keywords)
                              .keys()
                              .map((el) => Number(el))
                              .max()
                              .value() + 1;
                          setKeywords({
                            ...keywords,
                            [key]: { keyword: el, reason: '' },
                          });
                        }
                      }}
                      key={el}
                    >
                      {el}
                    </div>
                  );
                })}
            </div>
          </Label.col>
        )}
        <Label.col>
          <Label.Text children="선택단어/근거" />
          <div className="flex flex-col space-y-2 ">
            {Object.entries(keywords).map(([id, { keyword, reason }]) => (
              <div key={id} className="flex flex-col space-y-2">
                <TextInput
                  value={keyword}
                  onChange={(e) => {
                    setKeywords({ ...keywords, [id]: { keyword: e.target.value, reason } });
                  }}
                />
                <Textarea
                  rows={3}
                  className="h-16"
                  placeholder="특성단어를 선택한 근거를 작성해주세요."
                  value={reason}
                  onChange={(e) => {
                    setKeywords({ ...keywords, [id]: { keyword, reason: e.target.value } });
                  }}
                />
              </div>
            ))}
            <div
              className="flex cursor-pointer items-center justify-center space-x-2 rounded-lg border border-gray-600 bg-slate-50 py-2 text-gray-600"
              onClick={() => {
                if (Object.values(keywords).some(({ keyword }) => keyword === '')) {
                  alert('추가한 단어를 먼저 입력해주세요.');
                  return;
                }
                const key =
                  _.chain(keywords)
                    .keys()
                    .map((el) => Number(el))
                    .max()
                    .value() + 1;
                setKeywords({ ...keywords, [key]: { keyword: '', reason: '' } });
              }}
            >
              <div> 단어 추가 </div>
              <Icon.Plus />
            </div>
          </div>
        </Label.col>
        <Label.col>
          <Label.Text children="자기평가" />
          <Textarea
            placeholder="추가적으로 표현하고 싶은 자기평가를 작성해주세요."
            onChange={(e) => setAssessment(e.target.value)}
            value={assessment}
          />
        </Label.col>
        <Button.lg
          children="자기 평가 제출하기"
          className="bg-brand-1 text-white disabled:bg-gray-300 disabled:text-gray-600"
          disabled={buttonDisabled}
          onClick={() => {
            createSelfAssessment({
              data: {
                category1,
                category2,
                keywords,
                assessment,
                year: new Date().getFullYear().toString(),
              },
            });
          }}
        />
      </div>
    </>
  );
};
