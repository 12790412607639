import _ from 'lodash';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { GeneralGPTModal } from 'src/components/activityv3/GPT/GeneralGPTModal';
import { Blank, Label, Select, Textarea } from 'src/components/common';
import { Button } from 'src/components/common/Button';
import { Checkbox } from 'src/components/common/Checkbox';
import { Icon } from 'src/components/common/icons';
import { TextInput } from 'src/components/common/TextInput';
import { useTeacherCounseling } from 'src/container/teacher-counseling';
import {
  useStudentSelfAssessmentFindStudentAssessment,
  useTeacherStudentAssessmentCreate,
  useTeacherStudentAssessmentFindStudentAssessment,
} from 'src/generated/endpoint';
import { SELF_TEST_TYPES } from 'src/pages/student/self-test/self-test.type';
import { twMerge } from 'tailwind-merge';

export const GeneralCardPage: FC = () => {
  const { id } = useParams<{ id: string }>();

  const [category1, setCategory1] = useState('');
  const [category2, setCategory2] = useState('');
  const [keywords, setKeywords] = useState<Record<number, { keyword: string; reason: string }>>({});
  const [assessment, setAssessment] = useState<string>('');
  const [open, setOpen] = useState(false);

  const [selectedStudentIds, setSelectedStudentIds] = useState<number[]>([]);
  const [selectedCounselingIds, setSelectedCounselingIds] = useState<number[]>([]);
  const [selectedTeacherIds, setSelectedTeacherIds] = useState<number[]>([]);

  const { counselingData, isLoading: counselingLoading } = useTeacherCounseling(Number(id));

  const { data: studentSelfAssessment, isLoading: studentLoading } = useStudentSelfAssessmentFindStudentAssessment(
    Number(id),
  );

  const { data: teacherStudentAssessment, isLoading: teacherLoading } =
    useTeacherStudentAssessmentFindStudentAssessment(Number(id));

  const { mutate: createTeacherStudentAssessment } = useTeacherStudentAssessmentCreate();

  useEffect(() => {
    if (teacherStudentAssessment && !category1 && !category2 && !Object.values(keywords).length && !assessment) {
      setCategory1(teacherStudentAssessment?.category1);
      setCategory2(teacherStudentAssessment?.category2);
      setKeywords(teacherStudentAssessment?.keywords);
      setAssessment(teacherStudentAssessment?.assessment);
    }
  }, [teacherStudentAssessment]);

  const buttonDisabled = !category1 || !category2 || !Object.keys(keywords).length;

  const isLoading = counselingLoading || studentLoading || teacherLoading;

  return (
    <>
      {isLoading && <Blank />}
      <div className="scroll-box mt-4 h-screen-12 overflow-y-auto pb-4 md:h-screen-4">
        {/* Desktop V */}
        <div className="hidden md:block">
          <div className="h-screen-4 space-y-4 overflow-hidden md:flex md:space-x-4 md:space-y-0 md:overflow-y-hidden md:p-4">
            <div className="w-full rounded-xl border border-gray-300 bg-white">
              <div className="flex h-full flex-col space-y-4 overflow-y-auto p-4">
                <h1 className="text-xl font-bold text-gray-600">학생 자기 평가</h1>
                <div>
                  {studentSelfAssessment?.keywords &&
                    Object.entries(studentSelfAssessment.keywords).map(([id, { keyword, reason }]) => (
                      <label key={id} className="flex items-start space-x-2">
                        <Checkbox
                          className="mt-1"
                          checked={selectedStudentIds.includes(Number(id))}
                          onChange={() =>
                            selectedStudentIds.includes(Number(id))
                              ? setSelectedStudentIds(selectedStudentIds.filter((el) => el !== Number(id)))
                              : setSelectedStudentIds(selectedStudentIds.concat(Number(id)))
                          }
                        />
                        <div>
                          {keyword} : {reason}
                        </div>
                      </label>
                    ))}
                </div>
                <h1 className="text-xl font-bold text-gray-600">학생 상담 기록</h1>
                <div>
                  {counselingData?.map(({ id, content }) => (
                    <label key={id} className="flex items-start space-x-2">
                      <Checkbox
                        className="mt-1"
                        checked={selectedCounselingIds.includes(id)}
                        onChange={() =>
                          selectedCounselingIds.includes(id)
                            ? setSelectedCounselingIds(selectedCounselingIds.filter((el) => el !== id))
                            : setSelectedCounselingIds(selectedCounselingIds.concat(id))
                        }
                      />
                      <div>{content}</div>
                    </label>
                  ))}
                </div>
                <h1 className="text-xl font-bold text-gray-600">교사 학생 평가</h1>
                <div className="flex flex-col space-y-4">
                  <Label.col>
                    <Label.Text children="범주1" />
                    <Select.lg
                      placeholder="선택"
                      value={category1}
                      onChange={(e) => {
                        setCategory1(e.target.value);
                        setCategory2('');
                      }}
                    >
                      <option selected hidden value="">
                        범주를 선택해주세요.
                      </option>
                      {SELF_TEST_TYPES.map((ct1) => (
                        <option key={ct1.id} value={ct1.name}>
                          {ct1.name}
                        </option>
                      ))}
                    </Select.lg>
                  </Label.col>
                  <Label.col>
                    <Label.Text children="범주2" />
                    <Select.lg
                      placeholder="선택"
                      disabled={!category1}
                      value={category2}
                      onChange={(e) => {
                        setCategory2(e.target.value);
                      }}
                    >
                      <option selected hidden value="">
                        범주를 선택해주세요.
                      </option>
                      {category1 &&
                        SELF_TEST_TYPES.filter((ct1) => ct1.name === category1)[0].values.map((ct2) => (
                          <option key={ct2.id} value={ct2.name}>
                            {ct2.name}
                          </option>
                        ))}
                    </Select.lg>
                  </Label.col>
                  {category2 && (
                    <Label.col>
                      <Label.Text children="특성단어" />
                      <Label.Text className="text-16">학생을 나타내는 특성단어를 선택해주세요.</Label.Text>
                      <div className="mt-2">
                        {SELF_TEST_TYPES.filter((ct1) => ct1.name === category1)[0]
                          .values.filter((ct2) => ct2.name === category2)[0]
                          .values.map((el) => {
                            const selected = Object.values(keywords).some(({ keyword, reason }) => el === keyword);
                            return (
                              <div
                                className={twMerge(
                                  'mb-2 mr-2 inline-block cursor-pointer rounded-full border border-gray-600 px-4 py-2',
                                  selected && 'border-brand-1 bg-light_orange text-brand-1',
                                )}
                                onClick={() => {
                                  if (selected) {
                                    setKeywords(_.omitBy(keywords, ({ keyword }) => keyword === el));
                                  } else {
                                    const key =
                                      _.chain(keywords)
                                        .keys()
                                        .map((el) => Number(el))
                                        .max()
                                        .value() + 1;
                                    setKeywords({
                                      ...keywords,
                                      [key]: { keyword: el, reason: '' },
                                    });
                                  }
                                }}
                                key={el}
                              >
                                {el}
                              </div>
                            );
                          })}
                      </div>
                    </Label.col>
                  )}
                  <Label.col>
                    <Label.Text children="선택단어/근거" />
                    <div className="flex flex-col space-y-2 ">
                      {Object.entries(keywords).map(([id, { keyword, reason }]) => (
                        <div key={id} className="flex flex-col space-y-2">
                          <TextInput
                            value={keyword}
                            onChange={(e) => {
                              setKeywords({ ...keywords, [id]: { keyword: e.target.value, reason } });
                            }}
                          />
                          <Textarea
                            rows={3}
                            className="h-16"
                            placeholder="특성단어를 선택한 근거를 작성해주세요."
                            value={reason}
                            onChange={(e) => {
                              setKeywords({ ...keywords, [id]: { keyword, reason: e.target.value } });
                            }}
                          />
                        </div>
                      ))}
                      <div
                        className="flex cursor-pointer items-center justify-center space-x-2 rounded-lg border border-gray-600 bg-slate-50 py-2 text-gray-600"
                        onClick={() => {
                          if (Object.values(keywords).some(({ keyword }) => keyword === '')) {
                            alert('추가한 단어를 먼저 입력해주세요.');
                            return;
                          }
                          const key =
                            _.chain(keywords)
                              .keys()
                              .map((el) => Number(el))
                              .max()
                              .value() + 1;
                          setKeywords({ ...keywords, [key]: { keyword: '', reason: '' } });
                        }}
                      >
                        <div> 단어 추가 </div>
                        <Icon.Plus />
                      </div>
                    </div>
                  </Label.col>
                  <Label.col>
                    <Label.Text children="학생평가" />
                    <Textarea
                      placeholder="추가적으로 표현하고 싶은 학생 평가를 작성해주세요."
                      onChange={(e) => setAssessment(e.target.value)}
                      value={assessment}
                    />
                  </Label.col>
                  <Button.lg
                    children="학생 평가 제출하기"
                    className="bg-brand-1 text-white disabled:bg-gray-300 disabled:text-gray-600"
                    disabled={buttonDisabled}
                    onClick={() => {
                      createTeacherStudentAssessment({
                        data: {
                          category1,
                          category2,
                          keywords,
                          assessment,
                          year: new Date().getFullYear().toString(),
                          studentId: Number(id),
                        },
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="flex w-full flex-col space-y-2 rounded-xl border border-gray-600 bg-white p-4">
              <div className="flex items-center justify-between">
                <div className="flex flex-row items-center gap-1">
                  <div className="text-18 font-bold">작성한 활동기록 초안</div>
                </div>
                <Button.lg className="filled-primary" onClick={() => setOpen(true)}>
                  작성하기
                </Button.lg>
              </div>
              <div className="scroll-box h-full overflow-y-scroll"></div>
            </div>
          </div>
        </div>
      </div>
      {open && studentSelfAssessment && counselingData && teacherStudentAssessment && (
        <>
          <div className="fixed inset-0 z-10 bg-littleblack"></div>
          <div className="scroll-box fixed inset-x-0 inset-y-12 z-20 flex flex-col overflow-y-scroll rounded-3xl border border-gray-300 md:inset-x-10 md:inset-y-10 md:flex-row">
            <GeneralGPTModal
              studentSelfAssessment={studentSelfAssessment}
              counselingData={counselingData}
              teacherStudentAssessment={teacherStudentAssessment}
              selectedStudentIds={selectedStudentIds}
              setSelectedStudentIds={setSelectedStudentIds}
              selectedCounselingIds={selectedCounselingIds}
              setSelectedCounselingIds={setSelectedCounselingIds}
              selectedTeacherIds={selectedTeacherIds}
              setSelectedTeacherIds={setSelectedTeacherIds}
              onClose={() => {
                setOpen(false);
              }}
            />
          </div>
        </>
      )}
    </>
  );
};
