import _, { range } from 'lodash';
import { useEffect, useState } from 'react';
import { useStudentScoreFileCheckTest } from 'src/container/insert-exam-score';
import { useAdminCommonFindAllKlassBySchool, useSchoolManagementGetSchoolInfo } from 'src/generated/endpoint';
import { Select } from '../common';
import { Admin } from '../common/Admin';
import { ExamScoresBatcbUploadComponent } from './ExamScoresBatchUploadComponent';
import { MockExamUploadModal } from './MockExamUploadModal';

export const ExamScoresComponent = () => {
  const thisYear = new Date().getFullYear();
  const [startYear, setStartYear] = useState(thisYear);
  const [year, setYear] = useState(new Date().getFullYear());
  const [grade, setGrade] = useState(1);
  const [isUpdate, setIsUpdate] = useState(false);

  const { data: school } = useSchoolManagementGetSchoolInfo();
  const { data: klasses } = useAdminCommonFindAllKlassBySchool({ year });
  const { data } = useStudentScoreFileCheckTest(grade, year);
  const uniqueGrades = _.uniq(_.map(klasses, 'grade'));
  const [modalOpen, setModalClose] = useState<boolean>(false);

  const TestScores = data?.scores || [];
  const groupedScores = _.groupBy(TestScores, 'month');

  const completeScoresByMonth = Object.keys(groupedScores).reduce(
    (acc, month) => {
      // klasses가 undefined가 아닌 경우에만 실행
      if (klasses) {
        acc[month] = klasses
          .filter((k) => k.grade === grade)
          .map((klass) => {
            const existingScore = groupedScores[month].find((score) => score.class === klass.klass);
            return existingScore || { class: klass.klass, month: Number(month), isSubmitted: false };
          });
      } else {
        acc[month] = [];
      }
      return acc;
    },
    {} as Record<string, { class: number; month: number; isSubmitted: boolean }[]>,
  );

  useEffect(() => {
    if (!school?.createdAt) return;
    setStartYear(new Date(school.createdAt).getFullYear());
  }, [school?.createdAt]);
  return (
    <Admin.Section className="w-full gap-0 px-0 py-0">
      <section className="mb-6 flex items-center justify-between">
        <div className="flex items-center gap-2">
          <Select value={year} onChange={(e) => setYear(Number(e.target.value))}>
            {range(thisYear, startYear - 1, -1).map((year) => (
              <option key={year} value={year}>
                {year}학년도
              </option>
            ))}
          </Select>

          <Select value={grade} onChange={(e) => setGrade(Number(e.target.value))}>
            {uniqueGrades.map((grade) => (
              <option key={grade} value={grade}>
                {grade}학년
              </option>
            ))}
          </Select>
        </div>
        <div className="flex items-center gap-2">
          <nav className="flex h-10 items-center gap-2 text-[15px] font-bold text-[#111111]">
            <button
              className={`min-w-[120px] rounded-lg border border-[#0066ff] bg-[#0066ff] px-3 py-2 text-white`}
              onClick={() => setModalClose(true)}
            >
              파일 업로드
            </button>
          </nav>
        </div>
      </section>
      {TestScores?.length > 0 ? (
        <>
          <Admin.Table className="mb-10 rounded-lg bg-white px-4 py-3 text-center">
            <Admin.TableHead className="border-b border-b-[#333333]">
              <Admin.TableRow>
                <Admin.TableHCell
                  className="w-1/4 text-center text-lg font-semibold text-[#333333]"
                  children="시행 월"
                />
                <Admin.TableHCell className="w-2/4 text-center text-lg font-semibold text-[#333333]" children="학급" />
                <Admin.TableHCell
                  className="w-1/4 text-center text-lg font-semibold text-[#333333]"
                  children="파일 업로드"
                />
              </Admin.TableRow>
            </Admin.TableHead>
            <Admin.TableBody>
              {Object.keys(completeScoresByMonth).map((month) =>
                completeScoresByMonth[month].map((score, index) => (
                  <tr key={`${score.class}-${month}-${index}`} className="border-b border-[#dddddd] bg-white">
                    {index === 0 && (
                      <td className="px-2 py-2 text-center font-semibold" rowSpan={completeScoresByMonth[month].length}>
                        {month}월
                      </td>
                    )}
                    <td className="px-2 py-2 text-center">
                      {grade}학년 {score.class}반
                    </td>
                    <td className={`px-2 py-2 text-center ${score.isSubmitted ? 'text-[#0066ff]' : 'text-[#aaaaaa]'}`}>
                      {score.isSubmitted ? '등록 완료' : '미제출'}
                    </td>
                  </tr>
                )),
              )}
            </Admin.TableBody>
          </Admin.Table>
        </>
      ) : (
        <ExamScoresBatcbUploadComponent />
      )}

      {modalOpen && (
        <MockExamUploadModal
          modalOpen={modalOpen}
          setModalClose={() => setModalClose(false)}
          width="w-[480px]"
          grade={grade}
          ablePropragation={true}
        />
      )}
    </Admin.Section>
  );
};
