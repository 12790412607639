import { ExamScoreBoard } from './ExamScoreBoard';
import { INDIExamScoreBoard } from './INDIExamScoreBoard';
import { ScoreStep } from './SemesterScoreCard';

interface ExamScoreBoardsProps {
  studentId: string;
  scoreType: ScoreStep;
  grade: number;
}

export function ExamScoreBoards({ studentId, scoreType, grade }: ExamScoreBoardsProps) {
  if (scoreType === 'INDIVIDUAL') {
    return <INDIExamScoreBoard studentId={studentId} grade={grade} />;
  }

  return <ExamScoreBoard studentId={studentId} />;
}
