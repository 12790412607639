import clsx from 'clsx';
import { range } from 'lodash';
import { createContext, useEffect, useMemo, useRef, useState } from 'react';
import { Link, Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { ReactComponent as ExitAdmin } from 'src/assets/svg/exit-admin.svg';
import { ReactComponent as LogoAdmin } from 'src/assets/svg/logo-admin.svg';
import { Toast } from 'src/components/Toast';
import { Select } from 'src/components/common';
import { useSchoolManagementGetSchoolInfo } from 'src/generated/endpoint';
import { Role } from 'src/generated/model';
import { useLanguage } from 'src/hooks/useLanguage';
import { Routes } from 'src/routes';
import { meState } from 'src/store';
import { ApprovalLinePage } from './approval-line/ApprovalLinePage';
import { GroupEditPage } from './group/GroupEditPage';
import { GroupPage } from './group/GroupPage';
import { KlassEditPage } from './klass/KlassEditPage';
import { KlassPage } from './klass/KlassPage';
import { ParentDetailsPage } from './parent/ParentDetailsPage';
import { ParentEditPage } from './parent/ParentEditPage';
import { ParentPage } from './parent/ParentPage';
import { SchoolPage } from './school/SchoolPage';
import { ScoreBatchPage } from './score/ScoreBatchPage';
import { ScorePage } from './score/ScorePage';
import { SmsPage } from './sms/SmsPage';
import { StudentBatchAdvancePage } from './student/StudentBatchAdvancePage';
import { StudentBatchPage } from './student/StudentBatchPage';
import { StudentDetailsPage } from './student/StudentDetailsPage';
import { StudentEditPage } from './student/StudentEditPage';
import { StudentPage } from './student/StudentPage';
import { TeacherBatchPage } from './teacher/TeacherBatchPage';
import { TeacherDetailsPage } from './teacher/TeacherDetailsPage';
import { TeacherEditPage } from './teacher/TeacherEditPage';
import { TeacherPage } from './teacher/TeacherPage';
import { TimetablePage } from './timetable/TimetablePage';

export const AdminContext = createContext({ year: new Date().getFullYear() });

export function AdminMainPage() {
  const me = useRecoilValue(meState);

  const scrollRef = useRef<HTMLDivElement>(null);

  const scrollToTop = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = 0;
    }
  };

  const { pathname } = useLocation();
  const thisYear = new Date().getFullYear();
  const [startYear, setStartYear] = useState(thisYear);
  const [year, setYear] = useState(new Date().getFullYear());

  const { data: school } = useSchoolManagementGetSchoolInfo();
  const { t, changeLanguage } = useLanguage();
  const adminRoutes = [
    { path: Routes.admin.school, component: SchoolPage, name: t('basic_information') },
    { path: Routes.admin.teacher.edit, component: TeacherEditPage },
    { path: Routes.admin.teacher.batch, component: TeacherBatchPage },
    { path: Routes.admin.teacher.new, component: TeacherEditPage },
    { path: Routes.admin.teacher.details, component: TeacherDetailsPage },
    { path: Routes.admin.teacher.index, component: TeacherPage, name: t('teacher') },
    { path: Routes.admin.student.edit, component: StudentEditPage },
    { path: Routes.admin.student.batch, component: StudentBatchPage },
    { path: Routes.admin.student.advance, component: StudentBatchAdvancePage },
    { path: Routes.admin.student.new, component: StudentEditPage },
    { path: Routes.admin.student.details, component: StudentDetailsPage },
    { path: Routes.admin.student.index, component: StudentPage, name: t('student') },
    { path: Routes.admin.parent.edit, component: ParentEditPage },
    { path: Routes.admin.parent.details, component: ParentDetailsPage },
    { path: Routes.admin.parent.index, component: ParentPage, name: t('parent') },
    { path: Routes.admin.klass.new, component: KlassEditPage },
    { path: Routes.admin.klass.details, component: KlassPage },
    { path: Routes.admin.klass.index, component: KlassPage, name: t('class') },
    { path: Routes.admin.group.new, component: GroupEditPage },
    { path: Routes.admin.group.details, component: GroupPage },
    { path: Routes.admin.group.index, component: GroupPage, name: t('group') },
    { path: Routes.admin.approvalLine, component: ApprovalLinePage, name: t('approval_line') },
    { path: Routes.admin.timetable, component: TimetablePage, name: t('class_schedule') },
    { path: Routes.admin.sms, component: SmsPage, name: t('sms_cost_management') },
    { path: Routes.admin.score.new.batch, component: ScoreBatchPage },
    { path: Routes.admin.score.index, component: ScorePage, name: t('grade_management') },
  ];

  useEffect(() => {
    if (!school?.createdAt) return;
    setStartYear(new Date(school.createdAt).getFullYear());
  }, [school?.createdAt]);

  function checkPermission(name: string) {
    let permitted = me?.role === Role.ADMIN;

    if (me && !permitted) {
      if (name === '기본정보') {
        permitted = me?.role === Role.ADMIN;
      } else if (name === '선생님') {
        permitted = me?.teacherPermission.adminTeacher || false;
      } else if (name === '학생') {
        permitted = me?.teacherPermission.adminStudent || false;
      } else if (name === '보호자') {
        permitted = me?.teacherPermission.adminParent || false;
      } else if (name === '학급') {
        permitted = me?.teacherPermission.adminClass || false;
      } else if (name === '그룹') {
        permitted = me?.teacherPermission.adminGroup || false;
      } else if (name === '결재라인') {
        permitted = me?.teacherPermission.adminApprovalLine || false;
      } else if (name === '시간표') {
        permitted = me?.teacherPermission.adminTimetable || false;
      } else if (name === '문자비용관리') {
        permitted = me?.teacherPermission.adminSms || false;
      }
    }

    scrollToTop();

    return permitted;
  }

  const adminRoutesPermitted = useMemo(
    () => adminRoutes.filter((route) => route.name && checkPermission(route.name)),
    [me, adminRoutes],
  );

  return (
    <AdminContext.Provider value={{ year }}>
      <header className="sticky top-0 z-20 flex h-16 justify-center border-b bg-white">
        <nav className="flex w-full max-w-screen-3xl items-center justify-evenly px-6">
          <div className="flex flex-1">
            <Link to="/" className="flex items-center space-x-1">
              <ExitAdmin />
              <span className="text-14 font-medium text-gray-500">{t('exit_admin_mode')}</span>
            </Link>
          </div>
          <div className="flex flex-1 justify-center">
            <Link to={Routes.admin.index}>
              <LogoAdmin />
            </Link>
          </div>
          <div className="flex flex-1 justify-end">
            {(me?.schoolId === 2 || me?.schoolId === 171) && (
              <button onClick={changeLanguage}>{t('select_language')}</button>
            )}
          </div>
        </nav>
      </header>

      <div className="relative mx-auto flex max-w-screen-3xl">
        <aside className="sticky top-16 h-[calc(100vh-4rem)] flex-shrink-0 overflow-y-auto">
          <nav className="flex w-60 flex-col p-4">
            <div className="mb-4 flex h-14 w-full items-center justify-center rounded-lg bg-blue-600">
              <p className="text-lg font-bold text-white">관리자모드 입니다.</p>
            </div>
            <Select value={year} onChange={(e) => setYear(Number(e.target.value))}>
              {range(thisYear, startYear - 1, -1).map((year) => (
                <option key={year} value={year}>
                  {year}&nbsp;
                  {t('school_year')}
                </option>
              ))}
            </Select>
            <div className="mt-4 flex flex-col space-y-1">
              {adminRoutesPermitted.map((route) => (
                <Link
                  children={route.name}
                  key={route.path}
                  to={route.path}
                  className={clsx(
                    'flex h-10 items-center rounded-lg px-4 text-14',
                    pathname.startsWith(route.path) ? 'bg-gray-100 font-bold' : 'hover:bg-gray-50',
                  )}
                />
              ))}
            </div>
          </nav>
        </aside>

        <main ref={scrollRef} className="scroll-box h-screen-4 flex-1 overflow-auto bg-white px-6">
          <Switch>
            {adminRoutes.map((route) => (
              <Route key={route.path} path={route.path} component={route.component} />
            ))}
            <Route path={Routes.admin.index}>
              <Redirect to={adminRoutesPermitted.length > 0 ? adminRoutesPermitted[0].path : Routes.admin.index} />
            </Route>
          </Switch>
        </main>
      </div>

      <Toast />
    </AdminContext.Provider>
  );
}
