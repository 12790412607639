import { Chart as ChartJS, registerables } from 'chart.js';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { Chart } from 'react-chartjs-2';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  useActivityCardGenerateGPTAnalysis,
  useActivityCardGetActivityGPTAnalysis,
  useStudentActivityV3SaveByTeacher,
} from 'src/generated/endpoint';
import { ActivityV3, Record } from 'src/generated/model';
import { useLanguage } from 'src/hooks/useLanguage';
import { meState, toastState } from 'src/store';
import { Textarea } from '../common';
import { Button } from '../common/Button';
import { Checkbox } from '../common/Checkbox';
import { TextInput } from '../common/TextInput';
import { Icon } from '../common/icons';

ChartJS.register(...registerables);

interface ActivityV3CardProps {
  activityv3: ActivityV3;
  studentId: number;
  openedCardIds: number[];
  setOpenedCardIds: Dispatch<SetStateAction<number[]>>;
  checkedCardIds: number[];
  setCheckedCardIds: (cardIds: number[]) => void;
}

export const ActivityV3Card: FC<ActivityV3CardProps> = ({
  activityv3,
  studentId,
  openedCardIds,
  setOpenedCardIds,
  checkedCardIds,
  setCheckedCardIds,
}) => {
  const { t, currentLang } = useLanguage();
  const me = useRecoilValue(meState);
  const [toastMsg, setToastMsg] = useRecoilState(toastState);

  const sav = activityv3.studentActivityV3s?.[0];
  const [isSubmitSummary, setSubmitSummary] = useState(false);
  const [record, setRecord] = useState('');
  const [summary, setSummary] = useState('');
  const [title, setTitle] = useState('');

  const { data, isLoading, refetch } = useActivityCardGetActivityGPTAnalysis(studentId, activityv3.id, {
    query: {
      enabled: openedCardIds.includes(activityv3.id) && me?.schoolId === 2,
    },
  });
  const gptContents: any = data;

  const { mutate: generateGPTAnalysis, isLoading: generateLoading } = useActivityCardGenerateGPTAnalysis({
    mutation: {
      onSuccess: () => {
        refetch();
      },
      onError: (error) => setToastMsg(error.message),
    },
  });

  const { mutate: saveStudentActivityV3 } = useStudentActivityV3SaveByTeacher({
    mutation: {
      onSuccess: () => {
        setToastMsg(t('text_saved_changes', '변경 사항이 저장되었습니다.'));
      },
      onError: (error) => setToastMsg(error.message),
    },
  });

  const options = {
    maintainAspectRatio: false,
    spanGaps: true,
    responsive: true,
    interaction: {
      mode: 'nearest' as const,
      intersect: false,
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        backgroundColor: 'rgb(21, 132, 235)',
        padding: 20,
        bodySpacing: 10,
        usePointStyle: false,
        titleFont: {
          size: 16,
          weight: 'bold' as const,
        },
        bodyFont: {
          size: 14,
          weight: 'bold' as const,
        },
        filter: (item: any) => item.parsed.y !== null,
        callbacks: {
          title: () => '',
          label: (tooltipItem: any) => {
            return `${tooltipItem.label} : ${tooltipItem.raw}점`;
          },
          afterLabel: (tooltipItem: any) => {
            //@ts-ignore
            const reason = Object.values(gptContents.analysis['탐구역량'])?.[tooltipItem.dataIndex]?.['근거'];
            console.log(tooltipItem, ' reason ', reason);
            return `${reason.match(/.{1,20}/g).join('\n')}`;
          },
        },
      },
    },
    scales: {
      r: {
        min: 1,
        max: 5,
        ticks: {
          stepSize: 1,
          display: false,
        },
        grid: {
          color: (context: any) => {
            if (context.index === 4) {
              return 'black'; // 마지막 기준선은 검은색
            }
            return '#DDDDDD'; // 나머지 기준선은 회색
          },
        },
        pointLabels: {
          font: {
            size: 16,
            weight: 'bold' as const,
          },
          padding: 20,
        },
      },
    },
    layout: {
      padding: 0,
    },
  };

  useEffect(() => {
    if (!record) setRecord(sav?.record || '');
    if (!summary) setSummary(sav?.summary || '');
    if (!title) setTitle(sav?.title || activityv3?.title || '');
  }, [sav]);

  const getIsSessionNotSubmitted = () => {
    if (!activityv3.activitySessions?.length) {
      return !activityv3.studentActivityV3s?.[0]?.studentText && !activityv3.studentActivityV3s?.[0]?.records?.length;
    }
    let isSubmitted = false;
    activityv3.activitySessions.map((session) => {
      if (session?.studentActivitySessions?.[0]?.isSubmitted) {
        isSubmitted = true;
      }
    });
    return !isSubmitted;
  };

  return (
    <div className="overflow-hidden rounded-lg border border-gray-600">
      <div
        className="flex cursor-pointer items-center justify-between space-x-2 px-4 py-2.5 "
        onClick={() =>
          openedCardIds.includes(activityv3.id)
            ? setOpenedCardIds(openedCardIds.filter((id) => activityv3.id !== id))
            : setOpenedCardIds(openedCardIds.concat(activityv3.id))
        }
      >
        <div className="flex items-center space-x-2">
          <Checkbox
            checked={checkedCardIds.includes(activityv3.id)}
            className="disabled:bg-gray-200"
            disabled={!sav?.studentText && getIsSessionNotSubmitted()}
            onChange={() =>
              checkedCardIds.includes(activityv3.id)
                ? setCheckedCardIds(checkedCardIds.filter((id) => activityv3.id !== id))
                : setCheckedCardIds(checkedCardIds.concat(activityv3.id))
            }
          />

          <div className="text-15 font-bold">
            <span className="text-sm text-brand-1">[{activityv3.subject}]</span> {activityv3.title}
          </div>
        </div>
        {openedCardIds.includes(activityv3.id) ? <Icon.ChevronDown /> : <Icon.ChevronUp />}
      </div>

      {openedCardIds.includes(activityv3.id) && (
        <div className="h-full w-full bg-white">
          {/* GPT 분석 내용 */}
          {isLoading ? (
            <div className="flex w-full justify-center space-x-2 py-4 text-center text-16 text-gray-600">
              <span className="h-6 w-6 animate-ping rounded-full bg-sky-400 opacity-75"></span>
              <div>{t('loading_gpt_analysis_results', 'GPT 분석 결과를 불러오는 중입니다...')}</div>
            </div>
          ) : (
            <>
              {gptContents && (
                <div className="flex w-full flex-col space-y-2 border-b border-gray-300 px-4 py-2">
                  <div className="mb-1 text-16 font-bold">{t('inquiry_skills', '탐구 역량')}</div>
                  <div className="relative -mb-2 -mt-2 h-[300px] w-full overflow-hidden">
                    <div className="h-[calc(100% + 40px)] absolute -bottom-2 -top-2 left-0 right-0">
                      <Chart
                        type="radar"
                        datasetIdKey="id"
                        options={options}
                        data={{
                          labels: Object.keys(gptContents.analysis['탐구역량']),
                          datasets: [
                            {
                              label: t('evidence', '근거'),
                              data: Object.values(gptContents.analysis['탐구역량']).map((el: any) => el['점수']),
                              fill: true,
                              backgroundColor: 'rgba(255, 127, 34, 0.32)',
                              borderColor: '#FF7F22',
                              pointBackgroundColor: '#FF7F22',
                              pointBorderColor: '#FF7F22',
                              pointStyle: 'circle',
                              borderWidth: 2,
                              pointRadius: 8,
                              pointHoverRadius: 10,
                            },
                          ],
                        }}
                      />
                    </div>
                  </div>
                  <div className="text-16 font-bold">{t('character_competency', '인성 역량')}</div>
                  {gptContents.analysis['인성역량']['인성역량'].map((value: any) => (
                    <div key={value} className="flex w-max rounded-md border border-gray-500 px-3 py-1 text-16">
                      {value}
                    </div>
                  ))}
                  <div className="text-16 font-bold">
                    {t('character_competency_evaluation_basis', '인성 역량 평가 근거')}
                  </div>
                  <div className="text-14">{gptContents.analysis['인성역량']['근거']}</div>
                </div>
              )}
            </>
          )}
          {/* 차시 내용 */}
          <div className="w-full border-b border-gray-300 px-4 py-2">
            <h1 className="font-semibold">{t('session_info', '차시 정보')}</h1>
            {activityv3.activitySessions?.map((session) => (
              <div className="flex w-full items-center justify-between py-0.5 text-14">
                <p>{session?.title}</p>
                <p>
                  {session?.studentActivitySessions?.[0]?.isSubmitted ? (
                    <span className="text-brand-1">{t('status_submit', '제출')}</span>
                  ) : (
                    <span className="text-gray-500">{t('status_not_submitted', '미제출')}</span>
                  )}
                </p>
              </div>
            ))}
          </div>

          {/* 활동 내용 */}
          <div className="w-full border-b border-gray-300 px-4 py-2">
            <h1 className="font-semibold">{t('common_phrases', '공통문구')}</h1>
            <p className="text-13 leading-5">{activityv3.commonText}</p>
          </div>
          <div className="w-full border-b border-gray-300 px-4 py-2">
            <div className="flex w-full items-center justify-between">
              <h1 className="font-semibold">{t('student_activity_report', '학생 활동 보고서')}</h1>
              <p className="text-14">
                {sav?.studentText ? (
                  <span className="text-brand-1">{t('status_submit', '제출')}</span>
                ) : (
                  <span className="text-gray-500">{t('status_not_submitted', '미제출')}</span>
                )}
              </p>
            </div>
            <p className="text-12 leading-5 text-gray-500">
              {t('text_content_written_by_student', '학생이 작성한 내용입니다.')}
            </p>
            <p className="text-13 leading-5">{sav?.studentText}</p>
          </div>
          <div className="w-full border-b border-gray-300 px-4 py-2">
            <h1 className="font-semibold">{t('observation_record', '관찰 기록')}</h1>
            <p className="text-12 leading-5 text-gray-500">
              {t('text_activity_memo', '활동진행 중 학생에 대해 메모해 보세요.')}
            </p>
            <p className="whitespace-pre-line text-13 leading-5">
              {sav?.records?.map((record: Record) => (
                <>
                  <span>{record?.content}</span>
                  <br />
                </>
              ))}
            </p>
          </div>
          <div className="w-full border-b border-gray-300 px-4 py-2">
            <h1 className="font-semibold">{t('activity_summary', '활동요약')}</h1>
            <p className="text-12 leading-5 text-gray-500">
              {t('text_activity_summary', '공통문구, 학생 활동 보고서, 관찰 기록을 기반으로 활동요약을 작성합니다.')}
            </p>
            {isSubmitSummary ? (
              <>
                <TextInput
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  placeholder={t('activity_summary_title', '활동요약 제목')}
                  className="mt-2"
                />
                <Textarea
                  className="mt-2 rounded-lg border border-gray-300 p-2 text-13 leading-5"
                  value={summary}
                  placeholder={t('text_enter_activity_summary', '활동요약 내용을 작성해주세요.')}
                  onChange={(e) => setSummary(e.target.value)}
                />
                <div className="mt-2 flex w-full items-center space-x-2">
                  <Button
                    className="w-full border border-gray-800 bg-gray-50 text-gray-800"
                    onClick={() => setSummary(activityv3.commonText + '\n' + sav?.studentText + '\n' + record)}
                  >
                    {t('verb_load', '불러오기')}
                  </Button>
                  <Button
                    className="w-full border border-brand-1 text-brand-1"
                    onClick={() =>
                      saveStudentActivityV3({
                        params: { activityv3Id: activityv3.id, userId: studentId },
                        data: { record, summary, title },
                      })
                    }
                  >
                    {t('verb_save', '저장하기')}
                  </Button>
                </div>
              </>
            ) : (
              <>
                <div>
                  {sav?.title && (
                    <p className="w-full whitespace-pre-line border-b border-gray-300 text-14 leading-5">
                      {sav?.title}
                    </p>
                  )}
                  {sav?.summary && <p className="whitespace-pre-line text-13 leading-5">{sav.summary}</p>}
                </div>
                {me?.schoolId !== 183 && (
                  <Button
                    className="mt-2 w-full border border-gray-500 disabled:bg-gray-200 "
                    disabled={isLoading || generateLoading || !sav?.studentText}
                    onClick={() =>
                      generateGPTAnalysis({
                        studentId,
                        activityId: activityv3.id,
                      })
                    }
                  >
                    {gptContents
                      ? t('update_ai_activity_analysis', '활동 AI 분석 업데이트하기')
                      : t('ai_analyze_activity', '활동 AI 분석하기')}
                  </Button>
                )}
                <Button
                  className="mt-1 w-full border border-brand-1 text-brand-1"
                  onClick={() => setSubmitSummary(true)}
                >
                  {sav?.summary ? t('edit_announcement', '수정하기') : t('write', '작성하기')}
                </Button>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
