import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { languageState } from 'src/store';
import { Languages } from '../util/i18n';

interface UseLanguageReturnType {
  currentLang: Languages;
  t: (key: string, defaultText?: string, options?: Record<string, unknown>) => string;
  changeLanguage: () => void;
}

export const useLanguage = (): UseLanguageReturnType => {
  const { t: originalT, i18n } = useTranslation();
  const [currentLang, setCurrentLang] = useRecoilState(languageState);

  const changeLanguage = (): void => {
    const nextLang: Languages = currentLang === 'ko' ? 'en' : 'ko';
    i18n.changeLanguage(nextLang);
    localStorage.setItem('language', nextLang);
    setCurrentLang(nextLang);
  };

  const t = (key: string, defaultText?: string, options?: Record<string, unknown>): string => {
    const defaultValue = options?.defaultValue || defaultText;
    return originalT(key, { ...options, defaultValue });
  };

  return { currentLang, t, changeLanguage };
};
