import { Icon } from 'src/components/common/icons';

import { Link, Route, Switch, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { ReactComponent as RightArrow } from 'src/assets/svg/RightFillArrow.svg';
import { ReactComponent as Logo } from 'src/assets/svg/logo.svg';
import SvgUser from 'src/assets/svg/user.svg';
import { Constants } from 'src/constants';
import { meState } from 'src/store';
import { CASCreatePage } from './CAS/CASCreatePage';
import { CASMainPage } from './CAS/CASMainPage';
import { EECreatePage } from './EE/EECreatePage';
import { EEMainPage } from './EE/EEMainPage';
import { TOKCreatePage } from './TOK/TOKCreatePage';
import { TOKMainPage } from './TOK/TOKMainPage';

export const IBStudentMainPage = () => {
  const me = useRecoilValue(meState);

  const { pathname } = useLocation();

  return (
    <div className="flex">
      <div className="w-[270px] flex-shrink-0">
        <div className="flex h-screen flex-grow flex-col border-r border-gray-200 bg-gray-50 pb-4 pt-1 ">
          <div className="flex items-center justify-between px-4">
            <Link to={`/ib/student`}>
              <Logo className="w-20" />
            </Link>
            <div className="flex items-center space-x-4">
              <div
                onClick={() => {
                  window?.location?.reload();
                }}
                className="cursor-pointer text-sm text-brand-1"
              >
                새로고침
              </div>
              <div className="relative h-6 w-6">
                <Icon.Bell className="h-6 w-6 cursor-pointer" />
              </div>
            </div>
          </div>
          {/* 학생 프로필 */}
          <div className="mx-4 flex select-none items-center gap-4  py-2">
            <div className="flex h-[72px] w-[60px] rounded-xl">
              <img
                className="mx-auto rounded-xl"
                src={`${Constants.imageUrl}${me?.profile}`}
                alt=""
                loading="lazy"
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = SvgUser;
                  currentTarget.className = 'w-full rounded-xl';
                }}
              />
            </div>
            <div className="flex h-[72px] flex-1 flex-col justify-between gap-3 py-1 lg:px-2">
              <div>
                <div className="flex cursor-pointer items-center justify-between">
                  <p className="text-14 font-bold">{me?.name} 학생</p>
                  <RightArrow />
                </div>
                <p className="flex-1 overflow-hidden text-ellipsis whitespace-nowrap text-xs font-medium leading-none">
                  {me?.email}
                </p>
              </div>
              <div className="flex-1 overflow-hidden text-ellipsis whitespace-nowrap text-xs font-normal leading-none">
                {me?.school?.name}
              </div>
            </div>
          </div>
          {/* 메뉴바 */}
          <div className="scroll-box mt-5 flex h-screen-13 flex-grow flex-col overflow-y-auto tracking-tighter">
            <nav className={`flex-1 space-y-8 px-4 text-16`} aria-label="Sidebar">
              <div className="space-y-1">
                <div className="flex">
                  {/* 출결 start */}
                  <div className="w-20 self-center text-center font-bold text-darkgray">프로젝트</div>
                  <div className="my-2.5 ml-3 mr-2 border border-grey-6" />
                  <div className="w-40">
                    <Link
                      to="/ib/student/cas"
                      className={
                        pathname.startsWith('/ib/student/cas')
                          ? 'group flex items-center rounded-md bg-darkgray px-3 py-3 text-sm font-bold text-white'
                          : 'font-base group flex cursor-pointer items-center rounded-md px-3 py-3 text-sm text-darkgray hover:bg-gray-100 hover:text-gray-900'
                      }
                    >
                      CAS
                    </Link>
                    <Link
                      to="/ib/student/ee"
                      className={
                        pathname.startsWith('/ib/student/ee')
                          ? 'group flex items-center rounded-md bg-darkgray px-3 py-3 text-sm font-bold text-white'
                          : 'font-base group flex cursor-pointer items-center rounded-md px-3 py-3 text-sm text-darkgray hover:bg-gray-100 hover:text-gray-900'
                      }
                    >
                      EE
                    </Link>
                    <Link
                      to="/ib/student/tok"
                      className={
                        pathname.startsWith('/ib/student/tok')
                          ? 'group flex items-center rounded-md bg-darkgray px-3 py-3 text-sm font-bold text-white'
                          : 'font-base group flex cursor-pointer items-center rounded-md px-3 py-3 text-sm text-darkgray hover:bg-gray-100 hover:text-gray-900'
                      }
                    >
                      TOK
                    </Link>
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
      <div className="scroll-box h-screen w-full grid-cols-6 overflow-x-hidden overflow-y-scroll md:grid md:overflow-y-hidden">
        <Switch>
          <Route path="/ib/student/cas/create" component={CASCreatePage} />
          <Route path="/ib/student/cas" component={CASMainPage} />
          <Route path="/ib/student/ee/create" component={EECreatePage} />
          <Route path="/ib/student/ee" component={EEMainPage} />
          <Route path="/ib/student/tok/create" component={TOKCreatePage} />
          <Route path="/ib/student/tok" component={TOKMainPage} />
        </Switch>
      </div>
    </div>
  );
};
