/**
 * Generated by orval v6.11.1 🍺
 * Do not edit manually.
 * Super School 백엔드
 * Super School API 상세
 * OpenAPI spec version: 0.1
 */

export type UploadFileTypeEnum = (typeof UploadFileTypeEnum)[keyof typeof UploadFileTypeEnum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UploadFileTypeEnum = {
  'board/files': 'board/files',
  'board/images': 'board/images',
  'absents/images': 'absents/images',
  'activities/files': 'activities/files',
  'activities/images': 'activities/images',
  'activityv3/files': 'activityv3/files',
  'activityv3/images': 'activityv3/images',
  'test/canteens/images': 'test/canteens/images',
  'fieldtrips/images': 'fieldtrips/images',
  'newsletters/files': 'newsletters/files',
  'newsletters/images': 'newsletters/images',
  'notices/files': 'notices/files',
  'notices/images': 'notices/images',
  'test/studentactivities/images': 'test/studentactivities/images',
  'test/studentactivities/files': 'test/studentactivities/files',
  profiles: 'profiles',
  'profiles/customprofile': 'profiles/customprofile',
  'profiles/custombackground': 'profiles/custombackground',
  userpictures: 'userpictures',
  stamps: 'stamps',
  schoolmark: 'schoolmark',
  schoolstamp: 'schoolstamp',
  service_notice: 'service_notice',
  'counseling/voices': 'counseling/voices',
} as const;
