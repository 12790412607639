import { Role } from 'src/generated/model';

export const teacherRoles = [
  Role.ADMIN,
  Role.STAFF,
  Role.TEACHER,
  Role.PRE_HEAD,
  Role.HEAD,
  Role.PRE_PRINCIPAL,
  Role.PRINCIPAL,
  Role.VICE_PRINCIPAL,
  Role.HEAD_PRINCIPAL,
  Role.SECURITY,
  Role.GUEST_LECTURER,
];
