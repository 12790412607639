import { Admin } from 'src/components/common/Admin';
import { BatcbUploadComponent } from 'src/components/score/BatchUploadComponent';
import { useSchoolManagementGetSchoolInfo } from 'src/generated/endpoint';
import { useImageAndDocument } from 'src/hooks/useImageAndDocument';

export function ScoreBatchPage() {
  const { data: school } = useSchoolManagementGetSchoolInfo();
  const { documentObjectMap, toggleDocumentDelete, addFiles } = useImageAndDocument({});

  return (
    <Admin.Section className="h-full w-full">
      <Admin.H2 className="pt-4 text-2xl font-bold">성적관리</Admin.H2>
      <div className="flex items-center gap-2">
        <span className="h-2 w-2 rounded-full bg-blue-600" />
        <Admin.H3 className="text-xl font-semibold">초기 데이터 세팅</Admin.H3>
      </div>
      <BatcbUploadComponent
        documentObjectMap={documentObjectMap}
        toggleDocumentDelete={toggleDocumentDelete}
        addFiles={addFiles}
        schoolId={school?.id}
      />
    </Admin.Section>
  );
}
