interface MockScoreBoardProps {
  studentId: string;
  month: number;
}

export default function MockScoreBoard({ studentId, month }: MockScoreBoardProps) {
  const insertionYear = new Date().getFullYear();
  // const { scores, isLoading } = useStudentTestScore(Number(studentId), insertionYear);
  return (
    <div className="relative mt-4 overflow-x-auto rounded-lg text-14 shadow-md">
      <table className="w-full text-left text-gray-500 rtl:text-right">
        <thead className="bg-gray-200 uppercase text-gray-700">
          <tr>
            <th scope="col" className="h-4 w-1/6 border-r border-gray-300 p-2 text-center">
              영역
            </th>
            <th scope="col" className="h-4 w-1/6 border-r border-gray-300 p-2 text-center">
              과목
            </th>
            <th scope="col" className="w-1/6 border-r border-gray-300 px-2 py-2 text-center">
              원점수
            </th>
            <th scope="col" className="w-1/6 border-r border-gray-300 px-2 py-2 text-center">
              표준점수
            </th>
            <th scope="col" className="w-1/6 border-r border-gray-300 px-2 py-2 text-center">
              백분위
            </th>
            <th scope="col" className="w-1/6 px-2 py-2 text-center">
              등급
            </th>
          </tr>
        </thead>
        <tbody>
          <tr className="border-b bg-white">
            <td className="border-b border-r border-gray-300 px-2 py-2 text-center" rowSpan={1}>
              국어
            </td>
            <td className="border-r border-gray-300 px-2 py-2 text-center">국어</td>
            <td className="border-r border-gray-300 px-2 py-2 text-center">77</td>
            <td className="border-r border-gray-300 px-2 py-2 text-center">125.1</td>
            <td className="border-r border-gray-300 px-2 py-2 text-center">87.9</td>
            <td className="px-2 py-2 text-center">5</td>
          </tr>
          <tr className="border-b bg-white">
            <td className="border-b border-r border-gray-300 px-2 py-2 text-center" rowSpan={1}>
              수학
            </td>
            <td className="border-r border-gray-300 px-2 py-2 text-center">수학</td>
            <td className="border-r border-gray-300 px-2 py-2 text-center">64</td>
            <td className="border-r border-gray-300 px-2 py-2 text-center">112.9</td>
            <td className="border-r border-gray-300 px-2 py-2 text-center">71</td>
            <td className="px-2 py-2 text-center">4</td>
          </tr>
          <tr className="border-b bg-white">
            <td className="border-b border-r border-gray-300 px-2 py-2 text-center" rowSpan={1}>
              영어
            </td>
            <td className="border-r border-gray-300 px-2 py-2 text-center">영어</td>
            <td className="border-r border-gray-300 px-2 py-2 text-center">69</td>
            <td className="border-r border-gray-300 px-2 py-2 text-center">109.5</td>
            <td className="border-r border-gray-300 px-2 py-2 text-center">64.6</td>
            <td className="px-2 py-2 text-center">4</td>
          </tr>
          <tr className="border-b bg-white">
            <td className="border-b border-r border-gray-300 px-2 py-2 text-center" rowSpan={1}>
              한국사
            </td>
            <td className="border-r border-gray-300 px-2 py-2 text-center">한국사</td>
            <td className="border-r border-gray-300 px-2 py-2 text-center">44</td>
            <td className="border-r border-gray-300 px-2 py-2 text-center">64.7</td>
            <td className="border-r border-gray-300 px-2 py-2 text-center">91.9</td>
            <td className="px-2 py-2 text-center">1</td>
          </tr>
          <tr className="border-b bg-white">
            <td className="border-b border-r border-gray-300 px-2 py-2 text-center" rowSpan={1}>
              사회탐구
            </td>
            <td className="border-r border-gray-300 px-2 py-2 text-center">통합사회</td>
            <td className="border-r border-gray-300 px-2 py-2 text-center">37</td>
            <td className="border-r border-gray-300 px-2 py-2 text-center">52.3</td>
            <td className="border-r border-gray-300 px-2 py-2 text-center">52.8</td>
            <td className="px-2 py-2 text-center">2</td>
          </tr>
          <tr className="border-b bg-white">
            <td className="border-b border-r border-gray-300 px-2 py-2 text-center" rowSpan={1}>
              과학탐구
            </td>
            <td className="border-r border-gray-300 px-2 py-2 text-center">통합과학</td>
            <td className="border-r border-gray-300 px-2 py-2 text-center">30</td>
            <td className="border-r border-gray-300 px-2 py-2 text-center">53.9</td>
            <td className="border-r border-gray-300 px-2 py-2 text-center">65.8</td>
            <td className="px-2 py-2 text-center">3</td>
          </tr>
          {/* {Object.keys(currentScores).length > 0 ? (
            <>
              {Object.keys(currentScores).map((subject: string) => (
                <tr key={subject} className="border-b bg-white hover:bg-gray-50">
                  <td className="border-r border-gray-300 px-2 py-2">{formatSubject(subject)}</td>
                  <td className="border-r border-gray-300 px-2 py-2 text-center">{currentScores[subject]}</td>
                </tr>
              ))}
            </>
          ) : (
            <tr>
              <td colSpan={6} className="border-r border-gray-300 px-2 py-4 text-center">
                데이터가 추가되지 않았습니다
              </td>
            </tr>
          )} */}
        </tbody>
      </table>
    </div>
  );
}
