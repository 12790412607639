import clsx from 'clsx';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import SemesterScoreCard from 'src/components/studentCard/SemesterScoreCard';
import SubjectScoreCard from 'src/components/studentCard/SubjectScoreCard';

export type ScoreType = 'EXAM' | 'MOCKEXAM';

export const ScoreCardPage = () => {
  const { id } = useParams<{ id: string }>();
  const [type, setType] = useState<ScoreType>('EXAM');

  return (
    <div className="scroll-box h-screen-12 overflow-y-auto px-4 pb-4 md:h-screen-4">
      <nav className="my-2 flex w-full items-center rounded-lg border border-gray-300 text-lg font-semibold">
        <button
          onClick={() => setType('EXAM')}
          className={clsx(
            'w-1/2 rounded-lg border-r border-r-gray-300 py-2',
            type === 'EXAM' ? 'bg-[#ffe7db] text-[#ff600c]' : 'bg-white text-black',
          )}
        >
          내신
        </button>
        <button
          onClick={() => setType('MOCKEXAM')}
          className={clsx(
            'w-1/2 rounded-lg py-2',
            type === 'MOCKEXAM' ? 'bg-[#ffe7db] text-[#ff600c]' : 'bg-white text-black',
          )}
        >
          모의고사
        </button>
      </nav>
      <div className="flex flex-col gap-4 rounded-lg border border-gray-300 bg-white px-4 pb-4">
        <SubjectScoreCard studentId={id} type={type} />
        <SemesterScoreCard studentId={id} type={type} />
      </div>
    </div>
  );
};
