interface SelfTestType {
  id: number;
  name: string;
  values: {
    id: number;
    name: string;
    values: string[];
  }[];
}

export const SELF_TEST_TYPES: SelfTestType[] = [
  {
    id: 1,
    name: '성격 및 태도',
    values: [
      {
        id: 1,
        name: '긍정적',
        values: ['적극적인', '낙관적인', '자신감 있는', '긍정적인', '활기찬', '명랑한', '쾌활한', '희망적인'],
      },
      {
        id: 2,
        name: '성실함',
        values: ['성실한', '책임감 있는', '신뢰할 수 있는', '꾸준한', '진지한', '정직한', '꼼꼼한', '헌신적인'],
      },
      {
        id: 3,
        name: '열정적',
        values: ['열정적인', '열의 있는', '헌신적인', '주도적인', '동기부여가 강한', '열렬한', '열망하는', '몰두하는'],
      },
      {
        id: 4,
        name: '창의적',
        values: [
          '창의적인',
          '혁신적인',
          '독창적인',
          '상상력이 풍부한',
          '기발한',
          '새로운 아이디어를 가진',
          '유연한',
          '문제 해결 능력이 뛰어난',
        ],
      },
      {
        id: 5,
        name: '협력적',
        values: [
          '협력적인',
          '협동적인',
          '팀워크가 강한',
          '화합을 중시하는',
          '협조적인',
          '단결하는',
          '상호작용하는',
          '공조하는',
        ],
      },
      {
        id: 6,
        name: '친화적',
        values: [
          '사교적인',
          '친절한',
          '배려심 있는',
          '공감 능력이 뛰어난',
          '따뜻한',
          '마음이 넓은',
          '이해심 있는',
          '인간미 있는',
        ],
      },
    ],
  },
  {
    id: 2,
    name: '학업 및 학습 능력',
    values: [
      {
        id: 1,
        name: '학습능력',
        values: [
          '학습 능력이 뛰어난',
          '빠르게 배우는',
          '지식이 풍부한',
          '자기 개발에 열정적인',
          '이해력이 빠른',
          '다재다능한',
          '다방면에 관심이 많은',
          '지적 호기심이 강한',
        ],
      },
      {
        id: 2,
        name: '탐구심',
        values: [
          '탐구적인',
          '호기심 많은',
          '연구적인',
          '질문하는',
          '분석적인',
          '진리 탐구에 열정적인',
          '세심한',
          '철저한',
        ],
      },
      {
        id: 3,
        name: '성취도',
        values: [
          '높은 성취를 이루는',
          '목표 지향적인',
          '결과를 중시하는',
          '학업 성취도가 높은',
          '목표를 이루는',
          '성과가 높은',
          '성공적인',
          '노력하는',
        ],
      },
      {
        id: 4,
        name: '지속성',
        values: [
          '꾸준한',
          '지속적인',
          '인내심 있는',
          '끈기 있는',
          '끊임없이 노력하는',
          '결단력 있는',
          '포기하지 않는',
          '참을성 있는',
        ],
      },
    ],
  },
  {
    id: 3,
    name: '학교생활 및 활동',
    values: [
      {
        id: 1,
        name: '리더십',
        values: [
          '리더십 있는',
          '지도력이 있는',
          '결단력이 있는',
          '반장/동아리 회장을 맡은',
          '이끄는',
          '책임감 있는',
          '주도적인',
          '팀을 이끄는',
        ],
      },
      {
        id: 2,
        name: '동기부여',
        values: [
          '동기부여를 잘하는',
          '격려하는',
          '팀을 이끄는',
          '목표 설정을 잘하는',
          '영감을 주는',
          '긍정적인 영향을 주는',
          '자극을 주는',
          '힘을 북돋는',
        ],
      },
      {
        id: 3,
        name: '책임감',
        values: [
          '책임감 있는',
          '신뢰할 수 있는',
          '문제를 해결하는',
          '맡은 일을 끝까지 해내는',
          '믿음직한',
          '의무를 다하는',
          '의지를 가진',
          '결과를 중시하는',
        ],
      },
      {
        id: 4,
        name: '참여도',
        values: [
          '적극적인 참여자',
          '활동적인',
          '자발적인',
          '헌신적인',
          '주도적인',
          '능동적인',
          '열심히 참여하는',
          '열의 있는',
        ],
      },
    ],
  },
  {
    id: 4,
    name: '대인관계 및 협력',
    values: [
      {
        id: 1,
        name: '의사소통 능력',
        values: [
          '소통이 원활한',
          '경청하는',
          '설득력 있는',
          '대인관계가 좋은',
          '표현력이 뛰어난',
          '상호작용이 원활한',
          '의견을 잘 조율하는',
          '상호 이해하는',
        ],
      },
      {
        id: 2,
        name: '협력정신',
        values: [
          '협력적인',
          '팀워크를 중시하는',
          '함께 일하는',
          '협동심 있는',
          '동료애가 강한',
          '협조적인',
          '조화를 이루는',
          '단합하는',
        ],
      },
      {
        id: 3,
        name: '친절함',
        values: ['친절한', '배려심 있는', '공감하는', '예의 바른', '온화한', '다정한', '상냥한', '정중한'],
      },
    ],
  },
  {
    id: 5,
    name: '문제해결 및 창의성',
    values: [
      {
        id: 1,
        name: '문제해결능력',
        values: [
          '분석적인',
          '논리적인',
          '문제 해결 능력이 뛰어난',
          '창의적인',
          '전략적인',
          '계획적인',
          '체계적인',
          '혁신적인',
        ],
      },
      {
        id: 2,
        name: '창의적 사고',
        values: [
          '창의적인',
          '새로운 아이디어를 내는',
          '독창적인',
          '혁신적인',
          '유연한 사고를 가진',
          '상상력이 풍부한',
          '기발한',
          '다각적으로 생각하는',
        ],
      },
      {
        id: 3,
        name: '도전정신',
        values: [
          '도전적인',
          '새로운 것을 시도하는',
          '모험을 즐기는',
          '적극적인',
          '용기 있는',
          '결단력 있는',
          '실패를 두려워하지 않는',
          '도전 의식이 강한',
        ],
      },
    ],
  },
];
