import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useStudentSemetsersScore } from 'src/container/student-semesters-score';
import { List } from '../common';

interface ExamScoreBoardProps {
  studentId: string;
}

export function ExamScoreBoard({ studentId }: ExamScoreBoardProps) {
  const { scores, isLoading } = useStudentSemetsersScore(Number(studentId));
  const semesterOptions = [
    '성적분석',
    '1학년 1학기',
    '1학년 2학기',
    '2학년 1학기',
    '2학년 2학기',
    '3학년 1학기',
    '3학년 2학기',
  ];
  const [selectedSemester, setSelectedSemester] = useState<string>('성적분석');

  useEffect(() => {
    if (scores) {
      const newScoreDatas: any = {};

      scores.forEach((scoreData: any) => {
        const label = `${scoreData.grade}학년 ${scoreData.semester}학기`;

        if (!newScoreDatas[label]) {
          newScoreDatas[label] = [];
        }

        newScoreDatas[label].push(...scoreData.scores);
      });

      setScoreDatas(newScoreDatas);
    }
  }, [scores]);
  const [scoreDatas, setScoreDatas] = useState<any>({});

  // 교과별 성적 데이터 추출
  const groupedData = _.chain(scores)
    .map('scores')
    .flatten()
    .groupBy('subject_group')
    .mapValues((subjectScores) => _.groupBy(subjectScores, (score) => `${score.grade}학년 ${score.semester}학기`))
    .toPairs() // 객체를 [key, value] 쌍의 배열로 변환
    .sortBy(0) // 첫 번째 요소 (subject_group) 기준으로 정렬
    .fromPairs() // 다시 객체 형태로 변환
    .value();
  return (
    <>
      <List className="flex flex-row gap-2 md:m-0">
        {semesterOptions.map((sem, index) => (
          <React.Fragment key={sem}>
            <span
              onClick={() => setSelectedSemester(sem)}
              className={`${
                selectedSemester === sem ? 'font-bold' : 'text-gray-300'
              } cursor-pointer transition-all hover:font-bold hover:text-black`}
            >
              {sem}
            </span>
            {index < semesterOptions.length - 1 && <span className="mx-2 text-gray-300">|</span>}
          </React.Fragment>
        ))}
      </List>
      {selectedSemester === '성적분석' ? (
        <div className="relative mt-4 overflow-x-auto rounded-lg text-12 shadow-md">
          <table className="w-full text-left text-gray-500 rtl:text-right">
            <thead className="bg-gray-200 uppercase text-gray-700">
              <tr>
                <th scope="col" className="h-4 border-r border-gray-300 p-2 text-center">
                  교과
                </th>
                <th scope="col" className="border-r border-gray-300 px-2 py-2 text-center">
                  과목
                </th>
                <th scope="col" className="border-r border-gray-300 px-2 py-2 text-center">
                  학년
                </th>
                <th scope="col" className="min-w-max border-r border-gray-300 px-2 py-2 text-center">
                  학기
                </th>
                <th scope="col" className="border-r border-gray-300 px-2 py-2 text-center">
                  단위
                </th>
                <th scope="col" className="border-r border-gray-300 px-2 py-2 text-center">
                  원 점수
                </th>
                <th scope="col" className="border-r border-gray-300 px-2 py-2 text-center">
                  Z 점수
                </th>
                <th scope="col" className="border-r border-gray-300 px-2 py-2 text-center">
                  석차
                  <br />
                  <p className="text-10 text-gray-500">(동점자 수)</p>
                </th>
                <th scope="col" className="border-r border-gray-300 px-2 py-2 text-center">
                  등급
                </th>
                <th scope="col" className="border-r border-gray-300 px-2 py-2 text-center">
                  성취도
                </th>
                <th scope="col" className="border-r border-gray-300 px-2 py-2 text-center">
                  등급 분포
                </th>
                <th scope="col" className="px-2 py-2 text-center">
                  목표 등급
                </th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(groupedData).length > 0 ? (
                Object.keys(groupedData).map((subjectGroup) => (
                  <React.Fragment key={subjectGroup}>
                    {Object.keys(groupedData[subjectGroup]).map((semester, semesterIndex) => (
                      <React.Fragment key={semester}>
                        {groupedData[subjectGroup][semester].map((score, index) => (
                          <tr
                            key={`${score.subject_name}-${score.grade}-${score.semester}-${index}`}
                            className="border-b bg-white"
                          >
                            {index === 0 && semesterIndex === 0 && (
                              <td
                                className="border-b border-r border-gray-300 px-2 py-2 text-center text-lg font-semibold"
                                rowSpan={Object.values(groupedData[subjectGroup]).flat().length}
                              >
                                {subjectGroup}
                              </td>
                            )}
                            <td className="border-r border-gray-300 px-2 py-2">{score.subject_name}</td>
                            <td className="border-r border-gray-300 px-2 py-2">{`${score.grade}학년`}</td>
                            <td className="border-r border-gray-300 px-2 py-2">{`${score.semester}학기`}</td>
                            <td className="border-r border-gray-300 px-2 py-2 text-center">{score.credit}</td>
                            <td className="border-r border-gray-300 px-2 py-2 text-center">{score.score}</td>
                            <td className="border-r border-gray-300 px-2 py-2 text-center">
                              {Number(score.zscore).toFixed(2)}
                            </td>
                            <td className="border-r border-gray-300 px-2 py-2 text-center">
                              {score.num_same_score || '-'}
                            </td>
                            <td className="border-r border-gray-300 px-2 py-2 text-center">
                              {score.rank_score || '-'}
                            </td>
                            <td className="border-r border-gray-300 px-2 py-2 text-center">
                              {score.rank_alphabet || '-'}
                            </td>
                            <td className="border-r border-gray-300 px-2 py-2 text-center">
                              {Number(score.percentage).toFixed(1) || '-'}
                            </td>
                            <td className="px-2 py-2 text-center">{score.target_rank || '-'}</td>
                          </tr>
                        ))}
                      </React.Fragment>
                    ))}
                  </React.Fragment>
                ))
              ) : (
                <tr>
                  <td colSpan={12} className="border-r border-gray-300 px-2 py-4 text-center">
                    데이터가 추가되지 않았습니다
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="relative mt-4 overflow-x-auto rounded-lg text-14 shadow-md">
          <table className="w-full text-left text-gray-500 rtl:text-right">
            <thead className="bg-gray-200 uppercase text-gray-700">
              <tr>
                <th scope="col" className="h-4 border-r border-gray-300 p-2 text-center">
                  교과
                </th>
                <th scope="col" className="border-r border-gray-300 px-2 py-2 text-center">
                  과목
                </th>
                <th scope="col" className="border-r border-gray-300 px-2 py-2 text-center">
                  과목 종류
                </th>
                <th scope="col" className="border-r border-gray-300 px-2 py-2 text-center">
                  단위
                </th>
                <th scope="col" className="border-r border-gray-300 px-2 py-2 text-center">
                  원 점수
                </th>
                <th scope="col" className="border-r border-gray-300 px-2 py-2 text-center">
                  Z 점수
                </th>
                <th scope="col" className="border-r border-gray-300 px-2 py-2 text-center">
                  석차(동점자 수)
                </th>
                <th scope="col" className="border-r border-gray-300 px-2 py-2 text-center">
                  등급
                </th>
                <th scope="col" className="border-r border-gray-300 px-2 py-2 text-center">
                  성취도
                </th>
                <th scope="col" className="border-r border-gray-300 px-2 py-2 text-center">
                  등급 분포
                </th>
                <th scope="col" className="px-2 py-2 text-center">
                  목표 등급
                </th>
              </tr>
            </thead>
            <tbody>
              {scoreDatas[selectedSemester]?.length > 0 ? (
                _.sortBy(scoreDatas[selectedSemester], 'subject_group').map((score: any, index: number) => (
                  <tr
                    key={`${score.subject_name}-${score.grade}-${score.semester}-${index}`}
                    className="border-b bg-white hover:bg-gray-50"
                  >
                    <td className="border-r border-gray-300 px-2 py-2">{score.subject_group}</td>
                    <td className="border-r border-gray-300 px-2 py-2">{score.subject_name}</td>
                    <td className="border-r border-gray-300 px-2 py-2">{score.subject_type}</td>
                    <td className="border-r border-gray-300 px-2 py-2 text-center">{score.credit}</td>
                    <td className="border-r border-gray-300 px-2 py-2 text-center">{score.score}</td>
                    <td className="border-r border-gray-300 px-2 py-2 text-center">
                      {Number(score.zscore).toFixed(2)}
                    </td>
                    <td className="border-r border-gray-300 px-2 py-2 text-center">{score.num_same_score || '-'}</td>
                    <td className="border-r border-gray-300 px-2 py-2 text-center">{score.rank_score || '-'}</td>
                    <td className="border-r border-gray-300 px-2 py-2 text-center">{score.rank_alphabet || '-'}</td>
                    <td className="border-r border-gray-300 px-2 py-2 text-center">
                      {Number(score.percentage).toFixed(1) || '-'}
                    </td>
                    <td className="px-2 py-2 text-center">{score.target_rank || '-'}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={12} className="border-r border-gray-300 px-2 py-4 text-center">
                    데이터가 추가되지 않았습니다
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
}
