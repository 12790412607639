import { useQuery } from 'react-query';
import {
  getStudentExamScoreGetStudentExamScoresQueryKey,
  getStudentExamScoreGetStudentMockExamScoresQueryKey,
  getStudentExamScoreGetTestExamScoresQueryKey,
  studentExamScoreGetStudentExamScores,
  studentExamScoreGetStudentMockExamScores,
  studentExamScoreGetTestExamScores,
} from 'src/generated/endpoint';

export function useStudentSemetsersScore(userId: number) {
  const queryKey = getStudentExamScoreGetStudentExamScoresQueryKey(userId);

  const { data, isLoading } = useQuery(
    queryKey,
    ({ signal }) => studentExamScoreGetStudentExamScores(userId, {}, signal),
    { enabled: !!userId },
  );
  const scores = data?.exams_scores;

  return {
    scores,
    isLoading,
  };
}

export function useStudentMockTestScore(userId: number, insertionYear: number) {
  const queryKey = getStudentExamScoreGetStudentMockExamScoresQueryKey(userId, { insertionYear });

  const { data, isLoading } = useQuery<any>(
    queryKey,
    ({ signal }) => studentExamScoreGetStudentMockExamScores(userId, { insertionYear }, {}),
    { enabled: !!userId },
  );

  const scores = data?.scores;

  return {
    scores,
    isLoading,
  };
}

export function useStudentTestScore(studentId: number, insertionYear: number) {
  const queryKey = getStudentExamScoreGetTestExamScoresQueryKey({ studentId, insertionYear });

  const { data, isLoading } = useQuery<any>(
    queryKey,
    ({ signal }) => studentExamScoreGetTestExamScores({ studentId, insertionYear }, {}, signal),
    { enabled: !!studentId },
  );

  const scores = data?.test_exams_scores;

  return {
    scores,
    isLoading,
  };
}
