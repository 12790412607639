import React, { useState } from 'react';
import { Select as CommonSelect, List } from 'src/components/common';
import { ScoreType } from 'src/pages/teacher/studentcard/ScoreCardPage';
import { ExamScoreBoards } from './ExamScoreBoards';
import MockScoreBoard from './MockScoreBoard';

interface SemesterScoreCardProps {
  studentId: string;
  type: ScoreType;
}

export type ScoreStep = 'SYNTHESIS' | 'INDIVIDUAL';

export default function SemesterScoreCard({ studentId, type }: SemesterScoreCardProps) {
  return (
    <>
      {type === 'MOCKEXAM' ? (
        <MockExamScoreCard studentId={studentId} />
      ) : (
        <InternalAssessmentScoreCard studentId={studentId} />
      )}
    </>
  );
}

function MockExamScoreCard({ studentId }: { studentId: string }) {
  const MOCK_SCORE = [
    { label: '4월 모의고사', value: 4 },
    { label: '6월 모의고사', value: 6 },
    { label: '9월 모의고사', value: 9 },
  ];
  const [month, setMonth] = useState<number>(4);

  return (
    <main>
      <div className="flex w-full flex-col items-center space-x-2 md:flex-row">
        <List className="flex flex-row gap-2 md:m-0">
          {MOCK_SCORE.map((sem, index) => (
            <React.Fragment key={sem.label}>
              <span
                onClick={() => setMonth(sem.value)}
                className={`${
                  month === sem.value ? 'font-bold' : 'text-gray-300'
                } cursor-pointer transition-all hover:font-bold hover:text-black`}
              >
                {sem.label}
              </span>
              {index < MOCK_SCORE.length - 1 && <span className="mx-2 text-gray-300">|</span>}
            </React.Fragment>
          ))}
        </List>
      </div>
      <MockScoreBoard studentId={studentId} month={month} />
    </main>
  );
}

function InternalAssessmentScoreCard({ studentId }: { studentId: string }) {
  const [scoreType, setScoreType] = useState<ScoreStep>('SYNTHESIS');
  const [grade, setGrade] = useState<number>(2);

  const handleGradeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setGrade(Number(event.target.value));
  };

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    if (selectedValue === '종합성적') {
      setScoreType('SYNTHESIS');
    } else {
      setScoreType('INDIVIDUAL');
    }
  };

  return (
    <main>
      <div className="flex w-full flex-col items-center space-x-2 py-2 md:flex-row">
        <CommonSelect defaultValue={'종합성적'} onChange={handleSelectChange}>
          <option>종합성적</option>
          <option>개별성적</option>
        </CommonSelect>
        {scoreType === 'INDIVIDUAL' && (
          <CommonSelect defaultValue={2} onChange={handleGradeChange}>
            <option value={1}>1학년</option>
            <option value={2}>2학년</option>
            <option value={3}>3학년</option>
          </CommonSelect>
        )}
      </div>
      <ExamScoreBoards studentId={studentId} scoreType={scoreType} grade={grade} />
    </main>
  );
}
