import { useQuery } from 'react-query';
import {
  getStudentExamScoreGetStudentExamScoresChartQueryKey,
  getStudentExamScoreGetTestExamScoresChartQueryKey,
  studentExamScoreGetStudentExamScoresChart,
  studentExamScoreGetTestExamScoresChart,
} from 'src/generated/endpoint';

export function useStudentSubjectsScore(userId: number) {
  const queryKey = getStudentExamScoreGetStudentExamScoresChartQueryKey(userId);

  const { data, isLoading } = useQuery(
    queryKey,
    ({ signal }) => studentExamScoreGetStudentExamScoresChart(userId, {}, signal),
    { enabled: !!userId },
  );
  const scores = data?.exam_scores_chart;

  return {
    scores,
    isLoading,
  };
}

export function useStudentTestScore(studentId: number) {
  const insertionYear = new Date().getFullYear();
  const queryKey = getStudentExamScoreGetTestExamScoresChartQueryKey({ studentId, insertionYear });
  const { data, isLoading } = useQuery<any>(
    queryKey,
    ({ signal }) => studentExamScoreGetTestExamScoresChart({ studentId, insertionYear }, {}, signal),
    { enabled: !!studentId },
  );
  const scores = data?.test_exams_scores_chart;

  return {
    scores,
    isLoading,
  };
}
