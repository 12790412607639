import { Box, LinearProgress, LinearProgressProps, Typography } from '@mui/material';
import clsx from 'clsx';
import _ from 'lodash';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import superstudyLogo from 'src/assets/images/logo.png';
import neisLogo from 'src/assets/images/neis.png';
import AlertDialog from 'src/components/common/AlertDialog';
import ConfirmDialog from 'src/components/common/ConfirmDialog';
import { Icon } from 'src/components/common/icons';
import { useInsertScoreBatch } from 'src/container/insert-exam-score';
import { validateAndExtract } from 'src/util/exam-score';
import { isExcelFile } from 'src/util/file';

interface LinearProgressWithLabelProps extends LinearProgressProps {
  value: number;
  uploadedCount: number;
  totalCount: number;
}

function LinearProgressWithLabel(props: LinearProgressWithLabelProps) {
  const { value, uploadedCount, totalCount, ...rest } = props;
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" value={value} {...rest} />
      </Box>
      <Box minWidth={70} display={'flex'} gap={0.5} marginX={2}>
        <Typography variant="h6" color="text.primary">{`${Math.round(value)}%`}</Typography>
        <Typography variant="h6" color="text.secondary">{`(${uploadedCount}/${totalCount})`}</Typography>
      </Box>
    </Box>
  );
}

interface BatcbUploadComponentProps {
  documentObjectMap: Map<any, any>;
  toggleDocumentDelete: (key: any) => void;
  addFiles: (files: FileList) => void;
  schoolId: number | undefined;
}

export const BatcbUploadComponent: React.FC<BatcbUploadComponentProps> = ({
  documentObjectMap,
  toggleDocumentDelete,
  addFiles,
  schoolId,
}) => {
  const [isDragIn, setDragIn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [uploadStatus, setUploadStatus] = useState<{ name: string; uploaded: boolean }[]>([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const { push } = useHistory();
  const { insertScoreBatch, isLoading, errorMessage } = useInsertScoreBatch();

  const documentFiles = [...documentObjectMap.values()]
    .filter((value) => !value.isDelete && value.document instanceof File)
    .map((value) => value.document) as File[];

  const handleDrop: React.DragEventHandler<HTMLLabelElement> = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (loading) return;
    setLoading(true);
    const files = e.dataTransfer.files;
    if (!files || files.length === 0) return;
    validateAndAddFiles(files);
  };

  const validateAndAddFiles = (files: FileList) => {
    const invalidFiles = _.filter(files, (file) => {
      const fileName = file.name.split('.')[0];
      return !/^\d+-\d+$/.test(fileName) || !isExcelFile(file.name);
    });

    if (invalidFiles.length > 0) {
      alert('파일 이름이 "학년-반" 형식을 따르지 않거나 Excel 파일 형식이 아닌 파일이 있습니다.');
      setLoading(false);
      return;
    }

    const existingFileNames = [...documentObjectMap.values()]
      .filter((value) => !value.isDelete && value.document instanceof File)
      .map((value) => (value.document as File).name);

    const duplicateFiles = _.filter(files, (file) => existingFileNames.includes(file.name));

    if (duplicateFiles.length > 0) {
      alert('동일한 파일이 존재합니다.');
      setLoading(false);
      return;
    }

    addFiles(files);
    setLoading(false);
  };

  const handleUpload = async () => {
    if (!documentFiles.length) {
      alert('업로드할 파일이 없습니다.');
      return;
    }

    if (!schoolId) {
      alert('학교 정보를 불러오는 중입니다. 잠시 후 다시 시도해주세요.');
      return;
    }

    setUploading(true);
    setProgress(5);
    setUploadStatus(documentFiles.map((file) => ({ name: file.name, uploaded: false })));
    try {
      await uploadFilesWithProgress(documentFiles);
    } catch (error: any) {
      alert(`업로드 실패: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleCancelUpload = () => {
    setDialogOpen(!dialogOpen);
    setUploading(false);
    setProgress(0);
    setUploadStatus([]);
  };

  const uploadFilesWithProgress = async (files: File[]) => {
    const validFiles = validateAndExtract(files);
    if (validFiles.length === 0) return;

    let successCount = 0;
    for (const file of validFiles) {
      try {
        await insertScoreBatch(file); // 응답을 기다린 후 다음 파일 호출
        successCount++;
        setProgress(parseFloat(((successCount / validFiles.length) * 100).toFixed(2)));
        setUploadStatus((prevStatus) =>
          prevStatus.map((status) => (status.name === file.file.name ? { ...status, uploaded: true } : status)),
        );
      } catch (error: any) {
        console.error(error.message);
      }
    }
    setAlertOpen(!alertOpen);
    setUploading(false);
  };

  const uploadedCount = uploadStatus.filter((status) => status.uploaded).length;

  return (
    <>
      <div className="flex flex-row items-center justify-between pb-5">
        <div className="flex flex-row items-center gap-2">
          <span className="h-2 w-2 rounded-full bg-[#0066ff]" />
          <h1 className="text-[22px] font-semibold text-[#111111]">데이터 세팅</h1>
        </div>
        <label
          htmlFor="score-file"
          className={`h-10 min-w-[120px] cursor-pointer rounded-lg border border-[#0066ff] bg-[#0066ff] px-3 py-2 text-center text-[15px] font-bold text-white`}
        >
          파일 업로드
        </label>
      </div>
      {documentFiles.length > 0 ? (
        <div className="relative h-screen-20 rounded-lg bg-white">
          <div className="h-5/6 overflow-y-scroll">
            <div className="flex flex-wrap gap-6 p-8">
              {_.chain([...documentObjectMap.values()])
                .groupBy((value) => {
                  if (typeof value.document === 'string') return '기타';
                  const fileName = value.document.name.split('.')[0];
                  return fileName.split('-')[0] + '학년';
                })
                .map((files, grade) => (
                  <div key={grade} className="w-full">
                    <h6 className="text-xl font-semibold">{grade}</h6>
                    <div className="grid grid-cols-4 gap-6 p-2">
                      {files.map((value, key) => {
                        if (value.isDelete) {
                          return null;
                        }
                        const isUploaded = uploadStatus.find(
                          (status) => status.name === (value.document instanceof File ? value.document.name : ''),
                        )?.uploaded;
                        return (
                          <div className="min-w-1/4-4" key={key}>
                            <div
                              className={`flex h-12 w-full items-center justify-between rounded-lg border ${
                                isUploaded ? 'bg-neutral-100 text-[#aaaaaa]' : 'bg-white'
                              } px-4 py-3`}
                            >
                              <div className={`flex h-8 items-center space-x-2 rounded px-3 py-1`}>
                                {typeof value.document === 'string' ? (
                                  <></>
                                ) : (
                                  <div className={`w-full whitespace-pre-wrap break-words text-15`}>
                                    {value.document.name}
                                  </div>
                                )}
                              </div>
                              <div className="flex min-w-max items-center justify-center bg-white px-2 text-lightpurple-4">
                                {!uploading && (
                                  <div className="z-40 ml-2 block rounded-full text-center text-sm">
                                    <div
                                      className="flex h-full w-full cursor-pointer items-center justify-center text-white"
                                      onClick={() => toggleDocumentDelete(key)}
                                    >
                                      <Icon.Close className="cursor-pointer rounded-full bg-zinc-100 p-1 text-zinc-400" />
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ))
                .value()}
            </div>
          </div>
          <div className="flex h-1/6 w-full items-center justify-end gap-2 border-t border-t-neutral-200 px-10">
            {uploading ? (
              <>
                <div className="flex w-full items-center gap-4">
                  <div className="flex-grow flex-row">
                    <LinearProgressWithLabel
                      value={progress}
                      uploadedCount={uploadedCount}
                      totalCount={documentFiles.length}
                    />
                  </div>
                  <button
                    className="box-border rounded-lg border border-blue-600 px-10 py-3 text-lg font-bold text-blue-600"
                    onClick={() => setDialogOpen(!dialogOpen)}
                  >
                    업로드 취소
                  </button>
                </div>
              </>
            ) : (
              <div className="flex gap-2">
                <label
                  className="box-border cursor-pointer rounded-lg border border-blue-600 px-10 py-3 text-lg font-bold text-blue-600"
                  htmlFor="score-file"
                >
                  파일 추가하기
                  <input
                    type="file"
                    id="score-file"
                    name="score-file"
                    className="sr-only"
                    multiple
                    onChange={(e) => {
                      const files = e.target.files;
                      if (!files || files.length === 0) return;
                      validateAndAddFiles(files);
                    }}
                  />
                </label>
                <button
                  className="box-border rounded-lg border border-blue-600 bg-blue-600 px-10 py-3 text-lg font-bold text-white"
                  onClick={handleUpload}
                >
                  파일 업로드
                </button>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div>
          <input
            type="file"
            id="score-file"
            name="score-file"
            className="sr-only"
            multiple
            onChange={(e) => {
              const files = e.target.files;
              if (!files || files.length === 0) return;
              validateAndAddFiles(files);
            }}
          />
          <label
            htmlFor="score-file"
            className={clsx(
              'block w-full cursor-pointer rounded-lg border-t border-t-[#dddddd] py-20 text-center hover:bg-indigo-50 ',
              isDragIn ? 'bg-indigo-50' : ' bg-indigo-50',
            )}
            onDrop={handleDrop}
            onDragOver={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setDragIn(false);
            }}
            onDragEnter={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setDragIn(true);
            }}
          >
            <div className="flex flex-col items-center justify-center">
              <div className="flex flex-row items-center gap-4">
                <div>
                  <img className="mx-auto" src={neisLogo} alt="" />
                </div>
                <Icon.FillArrow className="-rotate-90" />
                <div>
                  <img className="mx-auto" src={superstudyLogo} alt="" />
                </div>
              </div>
              <h6 className="pt-4 text-xl font-bold text-zinc-800">
                나이스에서 다운로드 받은 파일을 업로드 해 주세요.
              </h6>
              <p className="flex items-center pt-2 text-neutral-500">
                {`1) 나이스에서 성적 파일 다운로드 시,`}
                <p className="flex items-center text-blue-600">
                  &nbsp;반드시
                  <p className="font-bold">&nbsp;&#39;XLS data&#39;로 다운로드</p>&nbsp;로 다운로드
                </p>
                해 주세요.
              </p>
              <p className="flex items-center pt-2 text-neutral-500">
                {`2)`}
                <p className="font-bold text-blue-600">
                  &nbsp;나이스에서 다운로드 받은 파일명 그대로(ex.1-1, 1-2 등) 업로드
                </p>
                해 주세요. {'(파일명, 내용 수정 시 오류가 발생합니다.)'}
              </p>
              <p className="flex items-center pb-4 pt-2 text-neutral-500">
                {`3) 파일은 .xlsx 형식만 업로드 할 수 있으며, 다중 선택 가능합니다,`}
              </p>
              <img
                src={'https://kr.object.gov-ncloudstorage.com/superschool/storage/material/score/neisflow.png'}
                className="rounded-lg object-cover"
              />
            </div>
          </label>
        </div>
      )}
      {dialogOpen && (
        <ConfirmDialog
          cancelText="취소"
          description={`업로드를 그만 하시려면 확인 버튼을,<br/>다시 진행 하시려면 취소 버튼을 눌러주세요.`}
          confirmText="확인"
          message="업로드를 취소하시겠습니까?"
          onCancel={() => setDialogOpen(!dialogOpen)}
          onConfirm={() => handleCancelUpload()}
          theme="secondary"
        />
      )}
      {alertOpen && (
        <AlertDialog
          message="업로드가 완료되었습니다."
          description={`첨부한 파일의 업로드가<br/>정상적으로 완료되었습니다.`}
          confirmText="확인"
          onConfirm={() => push('/admin/score')}
          theme="secondary"
        />
      )}
    </>
  );
};
