export interface examsScoreFiles {
  file: File;
  grade: number;
  class_num: number;
  cur_year: number;
}

export interface mockExamScoreFiles {
  file: File;
  grade: number;
  classNum: number;
  semester: number;
  step: number;
  insertionYear: number;
}

export function validateAndExtract(files: File[]) {
  const validFiles: examsScoreFiles[] = [];
  const invalidFiles: string[] = [];

  Array.from(files).forEach((file) => {
    const match = file.name.match(/^(\d+)-(\d+)\..+$/);
    if (match) {
      const grade = parseInt(match[1], 10);
      const class_num = parseInt(match[2], 10);
      const cur_year = new Date().getFullYear();
      validFiles.push({ file, grade, class_num, cur_year });
    } else {
      invalidFiles.push(file.name);
    }
  });

  if (invalidFiles.length > 0) {
    alert(`잘못된 파일 이름: ${invalidFiles.join(', ')}`);
    return [];
  }

  return validFiles;
}

export function validateAndExtractMock(files: File[], semester: number, step: number) {
  const validFiles: mockExamScoreFiles[] = [];
  const invalidFiles: string[] = [];

  Array.from(files).forEach((file) => {
    const match = file.name.match(/^(\d+)-(\d+)\..+$/);
    if (match) {
      const grade = parseInt(match[1], 10);
      const classNum = parseInt(match[2], 10);
      const insertionYear = new Date().getFullYear();
      validFiles.push({ file, grade, classNum, insertionYear, semester, step });
    } else {
      invalidFiles.push(file.name);
    }
  });

  if (invalidFiles.length > 0) {
    alert(`잘못된 파일 이름: ${invalidFiles.join(', ')}`);
    return [];
  }

  return validFiles;
}
