import { TextareaAutosize } from '@mui/material';
import { FC, useState } from 'react';
import { Checkbox } from 'src/components/common/Checkbox';
import ConfirmDialog from 'src/components/common/ConfirmDialog';
import { TextInput } from 'src/components/common/TextInput';
import { Icon } from 'src/components/common/icons';
import { Counseling, StudentSelfAssessment, TeacherStudentAssessment } from 'src/generated/model';
import 'swiper/swiper.min.css';

interface GeneralGPTModalProps {
  studentSelfAssessment: StudentSelfAssessment;
  counselingData: Counseling[];
  teacherStudentAssessment: TeacherStudentAssessment;
  selectedStudentIds: number[];
  setSelectedStudentIds: (ids: number[]) => void;
  selectedCounselingIds: number[];
  setSelectedCounselingIds: (ids: number[]) => void;
  selectedTeacherIds: number[];
  setSelectedTeacherIds: (ids: number[]) => void;
  onClose: () => void;
}

export const GeneralGPTModal: FC<GeneralGPTModalProps> = ({
  studentSelfAssessment,
  counselingData,
  teacherStudentAssessment,
  selectedStudentIds,
  setSelectedStudentIds,
  selectedCounselingIds,
  setSelectedCounselingIds,
  selectedTeacherIds,
  setSelectedTeacherIds,
  onClose,
}) => {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [confirmOpen, setConfirmOpen] = useState(false);
  const trimmedContent = content.replaceAll(' ', '');
  const byteLength = new TextEncoder().encode(trimmedContent).length;

  return (
    <div className="h-full w-full">
      <section className="flex h-full overflow-visible rounded-r-lg bg-[#e8ebf7] px-6 py-4">
        <div className="flex w-full rounded-lg bg-white">
          <div className={`flex h-full w-2/5 flex-col border-r border-gray-300 py-5`}>
            <div className="mb-2 h-full w-full overflow-y-scroll px-5 pb-4">
              <div className="flex items-center gap-2">
                <div className="text-xl font-bold">
                  활동기록부 초안 작성<span className="ml-1 align-top text-sm text-brandblue-1">AI</span>
                </div>
              </div>
              <div className="text-xs font-semibold text-[#666]">
                선택한 기록을 기반으로 활동기록부 초안을 작성합니다.
              </div>
              <div className="flex h-full flex-col space-y-4 overflow-y-auto p-4">
                <h1 className="text-xl font-bold text-gray-600">학생 자기 평가</h1>
                <div>
                  {studentSelfAssessment?.keywords &&
                    Object.entries(studentSelfAssessment.keywords).map(([id, { keyword, reason }]) => (
                      <label key={id} className="flex items-start space-x-2">
                        <Checkbox
                          className="mt-1"
                          checked={selectedStudentIds.includes(Number(id))}
                          onChange={() =>
                            selectedStudentIds.includes(Number(id))
                              ? setSelectedStudentIds(selectedStudentIds.filter((el) => el !== Number(id)))
                              : setSelectedStudentIds(selectedStudentIds.concat(Number(id)))
                          }
                        />
                        <div>
                          {keyword} : {reason}
                        </div>
                      </label>
                    ))}
                </div>
                <h1 className="text-xl font-bold text-gray-600">학생 상담 기록</h1>
                <div>
                  {counselingData?.map(({ id, content }) => (
                    <label key={id} className="flex items-start space-x-2">
                      <Checkbox
                        className="mt-1"
                        checked={selectedCounselingIds.includes(id)}
                        onChange={() =>
                          selectedCounselingIds.includes(id)
                            ? setSelectedCounselingIds(selectedCounselingIds.filter((el) => el !== id))
                            : setSelectedCounselingIds(selectedCounselingIds.concat(id))
                        }
                      />
                      <div>{content}</div>
                    </label>
                  ))}
                </div>
                <h1 className="text-xl font-bold text-gray-600">교사 학생 평가</h1>
                <div>
                  {teacherStudentAssessment?.keywords &&
                    Object.entries(teacherStudentAssessment.keywords).map(([id, { keyword, reason }]) => (
                      <label key={id} className="flex items-start space-x-2">
                        <Checkbox
                          className="mt-1"
                          checked={selectedTeacherIds.includes(Number(id))}
                          onChange={() =>
                            selectedTeacherIds.includes(Number(id))
                              ? setSelectedTeacherIds(selectedTeacherIds.filter((el) => el !== Number(id)))
                              : setSelectedTeacherIds(selectedTeacherIds.concat(Number(id)))
                          }
                        />
                        <div>
                          {keyword} : {reason}
                        </div>
                      </label>
                    ))}
                </div>
              </div>
            </div>
            <div className="min-h-max px-5">
              <button
                onClick={() =>
                  setContent(
                    `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
                  )
                }
                className="w-full rounded-lg bg-[#163192] py-2 text-base text-white disabled:bg-gray-500"
              >
                활동기록부 초안 작성
              </button>
            </div>
          </div>
          <div className={`flex w-3/5 flex-col overflow-x-visible p-5 pr-6`}>
            {!content && (
              <div className="flex h-full w-full flex-col items-center justify-center gap-10">
                <Icon.AIRobot className="h-60 w-60" />
                <h6 className="text-2xl font-bold text-[#466af0]">활동기록부 초안 작성을 눌러보세요!</h6>
              </div>
            )}
            {content && (
              <div className=" flex h-full w-full flex-col overflow-y-auto">
                <div className="text-xl font-bold">AI 작성 활동기록부 초안</div>
                <TextareaAutosize
                  value={content}
                  onChange={(e) => setContent(e.target.value)}
                  className="mt-2 rounded-md"
                />
                <div className="mt-2 flex items-center gap-2 pb-4">
                  <div>
                    <span className="text-gray-300">공백 제외 </span> {trimmedContent.length}자&nbsp;/&nbsp;{byteLength}
                    Byte
                  </div>
                  <div>
                    <span className="text-gray-300">공백 포함</span> {content.length}자&nbsp;/&nbsp;
                    {new TextEncoder().encode(content).length}Byte
                  </div>
                </div>
                <div className="border-t border-gray-300 pt-4">
                  <div className="font-bold">활동기록부 초안 제목</div>
                  <TextInput
                    placeholder="활동기록부 초안의 제목을 입력해 보세요."
                    className="mt-2 h-10 rounded-lg border border-[#CCCCCC]"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    required
                  />
                </div>
              </div>
            )}

            <div className="flex min-h-max justify-end space-x-2">
              <button
                children="나가기"
                onClick={() => setConfirmOpen(!confirmOpen)}
                className="box-border rounded-md border-2 border-[#163192] px-14 py-2 text-base font-semibold"
              />
              {content && (
                <button
                  children="저장하기"
                  onClick={() => onClose()}
                  className="box-border rounded-md border-2 border-gray-500 bg-[#163192] px-14 py-2 text-base font-semibold text-white"
                />
              )}
            </div>
          </div>
        </div>
      </section>
      {confirmOpen && (
        <ConfirmDialog
          cancelText="취소"
          confirmText="나가기"
          description="저장되지 않은 내용은 다시 불러올 수 없습니다.<br/> 한번 더 확인해 주세요."
          message="저장되지 않은 내용이 있습니다."
          onCancel={() => setConfirmOpen(!confirmOpen)}
          onConfirm={() => {
            onClose();
            setTitle('');
            setContent('');
          }}
        />
      )}
    </div>
  );
};
