import { ScoreType } from 'src/pages/teacher/studentcard/ScoreCardPage';
import ExamChart from './ExamChart';
import MockChart from './MockChart';

interface SubjectScoreCardProps {
  studentId: string;
  type: ScoreType;
}

export default function SubjectScoreCard({ studentId, type }: SubjectScoreCardProps) {
  return <>{type === 'MOCKEXAM' ? <MockChart studentId={studentId} /> : <ExamChart studentId={studentId} />}</>;
}
